define("huvepharma-aviapp-admin/utils/access-rights", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.emptyAccessRights = emptyAccessRights;
  _exports.hasAccessRights = hasAccessRights;
  _exports.findAccessRightsOrDefault = findAccessRightsOrDefault;
  _exports.userHasSomeAccess = userHasSomeAccess;

  function defaultAccessRights(company) {
    return {
      client: company.id,
      clientRights: {
        entireClient: false,
        companies: [],
        complexes: [],
        farms: []
      },
      geographicRights: {
        entireWorld: false,
        countries: [],
        regions: []
      }
    };
  }

  function emptyAccessRights() {
    return defaultAccessRights({
      id: ''
    });
  }

  function hasAccessRights(user, company) {
    return user.accessRights.some(function (item) {
      return item.client === company.id;
    });
  }

  function findAccessRightsOrDefault(accessRights, company) {
    var _accessRights$find;

    return (_accessRights$find = accessRights.find(function (item) {
      return item.client === company.id;
    })) !== null && _accessRights$find !== void 0 ? _accessRights$find : defaultAccessRights(company);
  }

  function hasAccessToAnyFarm(clientRights) {
    return !!(clientRights.entireClient || clientRights.companies.length || clientRights.complexes.length || clientRights.farms.length);
  }

  function hasAccessToAnyRegion(geographicRights) {
    return !!(geographicRights.entireWorld || geographicRights.countries.length || geographicRights.regions.length);
  }

  function userHasSomeAccess(clientAccessRights) {
    return hasAccessToAnyFarm(clientAccessRights.clientRights) && hasAccessToAnyRegion(clientAccessRights.geographicRights);
  }
});